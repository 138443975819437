import { validateDate } from './helpers';

export const VALIDATORS = {
  required: (value) => /\S+/.test(value),
  ga: (value) => /^$|^(ua|yt|mo)-\d{4,9}-\d{1,4}$/i.test(value),
  gtm: (value) => /^$|^(gtm)-[\da-z]{0,10}/i.test(value),
  latin: (value) => /^[\w-]+(\s[\w-]+|){1,29}/i.test(value),
  noSpace: (value) => /^\S+$/.test(value),
  // eslint-disable-next-line no-control-regex,max-len
  email: (value) => /(^((([a-z]|\d|[!#$%&'*+/=?^_`{|}~\-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+/=?^_`{|}~\-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((")(((([\u0009 ])*(\u000D\u000A))?([\u0009 ])+)?(([\u0001-\u0008\u000B\u000C\u000E-\u001F\u007F]|!|[\u0023-\u005B]|[\u005D-\u007E]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\u0001-\u0009\u000B-\u007F]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*((([\u0009 ])*(\u000D\u000A))?([\u0009 ])+)?(")))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$)|(^\s*$)/i.test(
    value
  ),
  password: (value) => /^\S{6,32}$/.test(value),
  // eslint-disable-next-line max-len
  url: (value) => /(^((?:(?:https?|ftp|http):)?\/\/(?:\S+(?::\S)?@)?|\S)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[01])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4])|(?:[\da-z\u00A1-\uFFFF]-*)*[\da-z\u00A1-\uFFFF]+(?:\.(?:[\da-z\u00A1-\uFFFF]-*)*[\da-z\u00A1-\uFFFF]+)*\.[a-z\u00A1-\uFFFF]{2,}\.?)(?::\d{2,5})?(?:[#/?]\S*)?$)|(^\s*$)/i.test(
    value
  ),
  // eslint-disable-next-line max-len
  domainUrl: (value) => /(http(s)?:makeFieldList\/.)?(www\.)?[\w#%+.:=@~-]{2,256}\.[A-Za-z]{2,6}\b([\w#%&+./:=?@~-]*)/.test(
    value
  ),
  number: (value) => /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:(\.|,)\d+)?$/.test(value),
  digits: (value) => /^\d+$/.test(value),
  // eslint-disable-next-line max-len
  phone: (value) => /^(\+ ?)?(\d+ ?(([/-]) ?)?)?(\( ?(\d+( ?(([/-]) ?\d+)*)?)+ ?\))? ?((([/-]) ?)?\d+ ?)+$/.test(
    value
  ),
  zip: (value) => /(^[\da-z]{1,7}(?:[\s-][\da-z]{1,5})?$)|(^\s*$)/i.test(value),
  // eslint-disable-next-line max-len
  username: (value) => /((^[a-z\u0080-\u024FА-я]+)+(([\s',._a-z\u0080-\u024FА-я-]+)|(\s[\s',._a-z\u0080-\u024FА-я-]+){1,29}))|(^\s*$)/i.test(
    value
  ),
  // eslint-disable-next-line max-len
  firstname: (value) => /((^[a-z\u0080-\u024FА-я]+)+(([\s',._a-z\u0080-\u024FА-я-]+)|(\s[\s',._a-z\u0080-\u024FА-я-]+){1,29}))|(^\s*$)/i.test(
    value
  ),
  twitter: (value) => /^(?!(^(twitter|admin)$))(\w){1,15}$/.test(
    value
  ),
  facebook: (value) => /^https?:\/\/(www\.)?(facebook|fb)\.com\/[\d.A-Za-z]{5,}$/.test(
    value
  ),
  currency: (value) => /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:(\.|,)\d+)?$/.test(value),
  lastname: (value) => /(^[A-z]+[\sA-z-]*$)?/gi.test(value),
  name: (value) => /(^(['-.A-Za-z\u0080-\u024F](?:\s*['-.A-Za-z\u0080-\u024F])*){2,200}$)|(^\s*$)/.test(
    value
  ),
  sitename: (value) => /((^[\dA-Za-z])+(([\w',.-])|(\s[\w',.-]))+$)/.test(value),
  logoname: (value) => /^[\s\w!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~\u0080-\u024F№\-]{1,63}$/.test(
    value
  ),
  city: (value) => /(^([A-Za-z\u0080-\u024F]+\s*(?:. |-| |')\s*)*[\sA-Za-z\u0080-\u024F]*$)|(^\s*$)/.test(
    value
  ),
  subject: (value) => /^[\W\dA-Za-z]{0,256}$/.test(value),
  // eslint-disable-next-line max-len
  address: (value) => /(^([A-Za-z\u0080-\u024F]+(?:. |-| |'))*[\d ,./A-Za-z\u0080-\u024F]*[\d A-Za-z\u0080-\u024F]$)|(^\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  youtubeId: (value) => /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
    value
  ),
  date: (value) => validateDate(value),
  state: (value) => /(^[A-Za-z]([\s\w',.\u0080-\u024F-]|[\d\sA-Za-z\u0080-\u024F]+)+)|(^\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  ipaddress: (value) => /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
    value
  ),
  // eslint-disable-next-line max-len
  ipv4: (value) => /(^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  ipv6: (value) => /(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$)/.test(
    value
  ),
};

export const FIELDS_TYPE = {
  TEXTAREA: 'textarea',
  INPUT: 'input',
  SELECT: 'select',
  ROW: 'row',
  SUBJECT: 'subject',
};

export const DATEPICKER_WRAPPER_CLASSES = {
  WRAPPER: '.input__icon-wrap',
  CONTAINER: '.qs-datepicker-container',
  NOT_CONTRASTED: 'not-contrasted',
  QS_HIDDEN: 'qs-hidden',
  ICON_HOLDER: '.input__icon-holder',
};

export const DATEPICKER_COLORS = {
  TEXT: 'var(--color_text)',
  BACKGROUND: '#fff',
  HOVER: 'E9EAEB',
  BRAND: 'var(--color_brand)',
  COMPLEMENTARY: 'var(--color_complementary)',
};

export const RATIO = {
  MAIN: 3,
  SECONDARY: 1.2,
};

export const SELECT_ITEM_TEMPLATE = (classNames, data) => (`
    <div
      class="${classNames.item} ${data.highlighted
    ? classNames.highlightedState
    : classNames.itemSelectable}
      ${data.placeholder ? classNames.placeholder : ''}"
      data-item data-id="${data.id}"
      data-value="${data.value}"
      ${data.active ? 'aria-selected="true"' : ''}
      ${data.disabled ? 'aria-disabled="true"' : ''}
    >
        ${data.label}
    </div>
`);

export const SELECT_CHOICE_TEMPLATE = (classNames, data) => (`
     <div
        ${data.placeholder ? 'style="display: none;"' : ''}
        class="${classNames.item} ${classNames.itemChoice} ${data.disabled
    ? classNames.itemDisabled
    : classNames.itemSelectable}"
        data-select-text=""
        data-choice ${data.disabled
    ? 'data-choice-disabled aria-disabled="true"'
    : 'data-choice-selectable'}
        data-id="${data.id}"
        data-value="${data.value}"
        ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
     >
       ${data.label}
     </div>
`);

export const SELECT_CLASS_NAMES = {
  containerOuter: 'Select',
  containerInner: 'Select-control',
  input: 'choices__input',
  inputCloned: 'choices__input--cloned',
  listItems: 'choices__list--multiple',
  listSingle: 'Select-multi-value-wrapper',
  listDropdown: 'Select-menu-outer',
  item: 'Select-value',
  itemSelectable: 'choices__item--selectable',
  itemDisabled: 'choices__item--disabled',
  itemChoice: 'Select-option',
  group: 'choices__group',
  groupHeading: 'choices__heading',
  button: 'choices__button',
  activeState: 'is-active',
  focusState: 'is-focused',
  openState: 'is-open',
  disabledState: 'is-disabled',
  highlightedState: 'is-highlighted',
  selectedState: 'is-selected',
  flippedState: 'is-flipped',
};

export const SELECT_CONFIG = {
  searchEnabled: false,
  shouldSort: false,
  itemSelectText: '',
  placeholder: true,
  classNames: SELECT_CLASS_NAMES,
  callbackOnCreateTemplates: (template) => ({
    item: (classNames, data) => template(SELECT_ITEM_TEMPLATE(classNames, data)),
    choice: (classNames, data) => template(SELECT_CHOICE_TEMPLATE(classNames, data)),
  }),
};

export const INPUT_WRAPPER_CLASS = 'input-wrap';
export const INPUT_WRAPPER_ERROR_CLASS = 'input-wrap_error';
export const INPUT_TEXT_ERROR_CLASS = 'input-error-text';
export const INPUT_ROW_CLASS = 'form__row';
export const INPUT_DESCRIPTION_SELECTOR = '.input-description';
export const INPUT_ERROR_ACTIVE_CLASS = `${INPUT_TEXT_ERROR_CLASS} input-error-text_visible`;
export const ONE_COLUMN_CLASS = 'row__item_desktop-2';

export const FORM_MESSAGE_CLASS = 'sent';
export const FORM_SPINNER_CLASS = 'spinner';
export const FORM_AUTO_CLOSE_CLASS = 'autoClose';
export const FORM_SUCCESS_SELECTOR = '.success';
export const FORM_ERROR_SELECTOR = '.error';
export const BTN_AUTO_CLOSE_SELECTOR = '.form-success__closeBtn';
export const FORM_TOOLTIP_SELECTOR = '.tooltip';
export const SHOW_TOOLTIP_CLASS = '_show-tooltip';

export const MESSAGE_TIMEOUT = 5000;
export const TOOLTIP_TIMEOUT = 3000;

export const CAPTCHA_ERROR_CLASS = 'g-recaptcha--error';
export const CAPTCHA_ERROR_TYPES = {
  CLASS_NAME: 'className',
  TEXT: 'text',
};
export const CAPTCHA_ERROR_ACTIONS = {
  SHOW: 'show',
  HIDE: 'hide',
};

export const DATE_OPTIONS = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

export const CUSTOM_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const DAY_FIRST = 'Day/Month/Year';
